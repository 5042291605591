import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SponsorsList from '../components/sponsors/sponsorslist';
import settings from '../../settings';
import ImageBackground from "../components/imagebackground";

const sponsors = [
  {
    id: 1,
    image: `${settings.IMAGES_BASE_URL}/images/sponsors/continental-tire-box-new.jpg`,
    imageAlt: 'Continental Tires',
    title: 'Continental Tires',
    text: "A trusted supplier since 1999 (our inaugural year, each of the 2,500 sets of tires we've used since then has enabled us to push our BMWs to the max with confidence.",
    url: "https://www.continentaltire.com/"
  },
  {
    id: 2,
    image: `${settings.IMAGES_BASE_URL}/images/sponsors/bmw-clubCar-box.jpg`,
    imageAlt: 'Club Car',
    title: 'Club Car',
    text:
      'The best way to get around our facility, Club Car makes gas or electric-powered carts that fit many lifestyles.',
    url: 'https://www.clubcar.com/us/en/home.html'
  },
  {
    id: 3,
    image: `${settings.IMAGES_BASE_URL}/images/sponsors/bmw-cca-box-new.jpg`,
    imageAlt: 'BMW Car Club of America',
    title: 'BMW Car Club of America',
    text: 'If you love all things BMW, this is the club for you. Members receive a 15 percent discount on select classes. May not be combined with any other discounts.',
    url: 'https://www.bmwcca.org/'
  },
  {
    id: 4,
    image: `${settings.IMAGES_BASE_URL}/images/sponsors/thermal-club-box-new.jpg`,
    imageAlt: 'The Thermal Club',
    title: 'The Thermal Club',
    text:
      'Home to the Performance Center West, The Thermal Club boasts world-class facilities and luxurious accommodations, all surrounded by the thrill of motorsport.',
    url: 'http://thethermalclub.com/'
  },
  {
    id: 5,
    image: `${settings.IMAGES_BASE_URL}/images/sponsors/bmw-cc-partners.jpg`,
    imageAlt: 'Rewards For The Driven',
    title: 'Rewards For The Driven',
    text:
      'Accelerate your rewards with the BMW Card – exclusively for BMW drivers. Redeem for unlimited cash back toward your BMW Financial Services monthly payment or travel. Plus, you’ll save when you use your BMW Card to book a class with us over the phone.',
      url: 'https://www.mybmwcreditcard.com/offer50'
  } ,
  {
    id: 6,
    image: `${settings.IMAGES_BASE_URL}/images/sponsors/pga-west-partner.jpg`,
    imageAlt: 'PGA WEST',
    title: 'PGA WEST',
    text:
      'Book a round of golf at the exclusive PGA WEST for a discounted rate when you book a class at our Thermal location.',
      url: 'https://www.pgawest.com/?utm_source=bmwpds&utm_medium=website&utm_campaign=pgawest'
  } ,
  {
    id: 7,
    image: `${settings.IMAGES_BASE_URL}/images/sponsors/aero.jpg`,
    imageAlt: 'Aero',
    title: 'Aero',
    text:
      'Skip the traffic and arrive in style. Aero is now flying on Fridays and Sundays between Los Angeles (VNY) and Palm Springs (TRM). Members receive a 10% discount on their flight.',
      url: 'https://aero.com?utm_source=Referral&utm_medium=partner&utm_campaign=PD_PRT_BMWPerformanceCenter_AE_PSP_CVR_NA_H-LOS_A-NAA_VNYSUN_ALW_NGS_MK_10PCT_V1&utm_campaign_coupon=BMW10'
  } ,

];

const banner =`${settings.IMAGES_BASE_URL}/images/home/faq-banner.jpg`;

const SponsorsPage = () => {
  return (
    <Layout>
      <SEO title="BMW Performance Driving School | Partners" description="From the tires we trust to the BMW Car Club of America, these are the partners that keep things moving on the right track - pun intended." />
      <div className="sponsors-page">
        <ImageBackground
          imageDesktop={banner}
          imageMobile={banner}
          imageAlt="press"
          imageClassName="banner"
          topLeftChildren={
            <div className="text__left">
              <h3>PARTNERS</h3>
            </div>
          }
          hideArrow
        />
        <section className="sponsors">
          <div className="container">
            <SponsorsList sponsors={sponsors} />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default SponsorsPage;
